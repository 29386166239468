import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  drawTriangle,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  drawPoint,
  drawTri,
  setSize,
  labelIt,
  drawDash,
  placeEggs,
  drawLine,
  setWidth,
  drawIntersection,
  drawPerpP,
  drawAngle,
  createSpace,
  createAxes,
  writeHTMLText
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var graph =createSpace(-8,8,-7,9);
    //graph.suspendUpdate();
    createAxes(graph);
    // Layering
    graph.options.axis.strokeWidth=0;
    graph.options.layer['image']=17;
    graph.options.layer['text']=19;
    graph.options.layer['line']=16;
    graph.options.layer['point']=17;
    graph.options.layer['glider']=17;
    graph.options.layer['angle']=18;
    graph.options.board.minimizeReflow = 'none';
    graph.options.point.showInfobox =false;
    graph.options.line.highlight=false;
    graph.options.image.highlight=false;
    graph.options.text.highlight=false;
    graph.options.polygon.highlight=false;
//    graph.options.text.display='internal';
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeLogo(graph);
    placeTitle(graph, 'Discriminant & Number of Solutions', '');
////////////////////////////////////////////////////////////////////////////////
    var analytics = placeUndo(graph, 'left');
    hoverMe(graph, analytics, 'Previous Step', 0, -5);
    ////////////////////////////////////////////////////
    var erase = placeRedo(graph);
    hoverMe(graph, erase, 'Next Step', 0, -5);
/////////////////////////////////////////////////////////////////////////////
    var i = 0;
    var eggs = placeEggs(graph);
    eggs[0].setAttribute({visible:()=>i>=0});
    ////////////////
    eggs[1].setAttribute({visible:()=>i>=1});
    ////////////////
    eggs[2].setAttribute({visible:()=>i>=2});
    /////////////////////
    const a = [1, 2,  3,   2,  1];
    const b = [1, 2,  3,   2,  4];
    const c = [-1, 1, 3/4, -2,  3];
    ///////////////////////////All actions go here////////////////////////////////
    var curve = graph.create('functiongraph', [function(x){return c[i]*1+ a[i]*x*x + b[i]*x;}, -5, 4], {strokeColor:'red', strokeWidth:4});
////////////////////////////////////////
    var eqn =writeHTMLText(graph, -7, 6.5, function(){return 'Equation: '+ a[i]+' x^2 + '+b[i]+' x = '+ -c[i]});
    eqn.setAttribute({anchorX:'left'});
    var D = writeHTMLText(graph, -7, -2.5, function(){return 'Discriminant D = '+ b[i]+'^2'+' - 4 ('+a[i]+' ) ( '+c[i]+' ) = '+ (b[i]*b[i]-4*a[i]*c[i]).toFixed(2)});
    D.setAttribute({anchorX:'left'});
    var x1 =drawPoint(graph, function(){return (-b[i] + Math.sqrt(b[i]*b[i] - 4*a[i]*c[i]))/(2*a[i])}, 0);
    setSize(graph, x1, 3);
    x1.setAttribute({strokeColor:'black', fillColor:'white'});
    var x2 = drawPoint(graph, function(){return (-b[i] - Math.sqrt(b[i]*b[i] - 4*a[i]*c[i]))/(2*a[i])}, 0);
    x2.setAttribute({strokeColor:'black', fillColor:'white'});
    setSize(graph, x2, 3);

    var two = placeLeftText(graph, -7, -3.5, function(){return 'This equation has TWO real solutions!'});
    two.setAttribute({visible:()=>b[i]*b[i]-4*a[i]*c[i]>0});
    var one = placeLeftText(graph, -7, -3.5, function(){return 'This equation has ONE real solution!'});
    one.setAttribute({visible:()=>b[i]*b[i]-4*a[i]*c[i]==0});
    var zero = placeLeftText(graph, -7, -3.5, function(){return 'This equation has NO real solution!'});
    zero.setAttribute({visible:()=>b[i]*b[i]-4*a[i]*c[i]<0});

  //////////////////////////////Button Actions////////////////////////////////////
    analytics.on('down', function(){
      if(i>0)
      {
      i-=1;
      }
      else
      {
        i=0;
      }
    });
    erase.on('down', function(){
      if(i<2)
      {
      i+=1;
      }
      else
      {
        i=0;
      }
    })
///////////////////////////////////////////////////////////////////////
    //graph.unsuspendUpdate();
                      },
              }
export default Boxes;
